import React from "react";

import { Router } from "@reach/router";
import { AccountProvider } from "@had/shop";
import Login from "../pages/login";
import AuthenticatedLayout from "../authenticatedlayout";
import { AUTH_URL, PRODUCT_VERSION } from "../../constants/config";

import "../../assets/css/bootstrap.css";
import "../../assets/css/layout.css";

function App() {
  return (
    <React.Suspense
      fallback={
        <div className="position-center">
          <div className="spinner-border text-primary" />
        </div>
      }
    >
      <AccountProvider version={PRODUCT_VERSION} authUrl={AUTH_URL}>
        <Router className="d-flex flex-column w-100 h-100">
          <Login path="/login" exact />
          <AuthenticatedLayout path="/*" />
        </Router>
      </AccountProvider>
    </React.Suspense>
  );
}

export default App;

import _objectWithoutPropertiesLoose from '@babel/runtime/helpers/esm/objectWithoutPropertiesLoose';
import _extends from '@babel/runtime/helpers/esm/extends';
import React from 'react';
import { PriceTypes } from '@had/common';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';
import memoizeIntlConstructor from 'intl-format-cache';
var getNumberFormat = memoizeIntlConstructor(Intl.NumberFormat);
var getDateTimeFormat = memoizeIntlConstructor(Intl.DateTimeFormat);

function formatPrice(price, priceType) {
  if (priceType === void 0) {
    priceType = PriceTypes.DECIMALS;
  }

  if (typeof price !== "object") return price;

  switch (priceType) {
    case PriceTypes.DECIMALS:
      return Number(price.high / price.low + 1);

    case PriceTypes.FRACTIONS:
      return price.high + " / " + price.low;

    default:
      throw new Error("No formatter for price of type: " + priceType + " found");
  }
}

function formatNumber(lng, value, rest) {
  return getNumberFormat(lng, rest).format(value);
}

function formatDate(lng, value, rest) {
  return getDateTimeFormat(lng, rest).format(parse(value));
}

function formatTime(lng, value, rest) {
  return getDateTimeFormat(lng, _extends({
    hour: "numeric",
    minute: "numeric",
    hour12: false
  }, rest)).format(parse(value));
}

var CurrencyDisplay = React.memo(function (_ref) {
  var amount = _ref.amount,
      currency = _ref.currency;

  var _useTranslation = useTranslation(),
      language = _useTranslation.i18n.language;

  return formatNumber(language, amount, {
    currency: currency,
    style: "currency"
  });
});
var NumberDisplay = React.memo(function (_ref2) {
  var value = _ref2.value;

  var _useTranslation2 = useTranslation(),
      language = _useTranslation2.i18n.language;

  if (typeof value !== "number") return value;
  return formatNumber(language, value);
});
var PriceDisplay = React.memo(function (_ref3) {
  var _ref3$price = _ref3.price,
      price = _ref3$price === void 0 ? null : _ref3$price,
      _ref3$type = _ref3.type,
      type = _ref3$type === void 0 ? PriceTypes.DECIMALS : _ref3$type;

  var _useTranslation3 = useTranslation(),
      t = _useTranslation3.t,
      language = _useTranslation3.i18n.language;

  if (price === null) return t("app.price_SP");
  var value = formatPrice(price, type);
  if (typeof value !== "number") return value;
  return formatNumber(language, value, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
});
var DateDisplay = React.memo(function (_ref4) {
  var date = _ref4.date,
      timezone = _ref4.timezone,
      rest = _objectWithoutPropertiesLoose(_ref4, ["date", "timezone"]);

  var formattedDate = typeof date === "string" ? parse(date) : date;

  var _useTranslation4 = useTranslation(),
      language = _useTranslation4.i18n.language;

  return formatDate(language, formattedDate, _extends({
    timeZone: timezone
  }, rest));
});
var TimeDisplay = React.memo(function (_ref5) {
  var date = _ref5.date,
      timezone = _ref5.timezone,
      rest = _objectWithoutPropertiesLoose(_ref5, ["date", "timezone"]);

  var formattedDate = typeof date === "string" ? parse(date) : date;

  var _useTranslation5 = useTranslation(),
      language = _useTranslation5.i18n.language;

  return formatTime(language, formattedDate, _extends({
    timeZone: timezone
  }, rest));
});
var DateTimeDisplay = React.memo(function (props) {
  return React.createElement(React.Fragment, null, React.createElement(DateDisplay, props), " ", React.createElement(TimeDisplay, _extends({}, props, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  })));
});
export { CurrencyDisplay, DateDisplay, DateTimeDisplay, NumberDisplay, PriceDisplay, TimeDisplay, formatDate, formatNumber, formatPrice, formatTime };
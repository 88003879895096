import i18n from "i18next";
import backend from "i18next-xhr-backend";
import detector from "i18next-browser-languagedetector";
const isDevelopment = process.env.NODE_ENV === "development";

i18n
  .use(backend)
  .use(detector)
  .init({
    ns: ["common"],
    defaultNS: "common",
    fallbackNS: "",
    fallbackLng: "en",
    debug: isDevelopment
  });

export default i18n;

import React from "react";
import { SettingsPanel } from "@had/settings-panel";
import { AccountContext, SettingsContext } from "@had/shop";

export default function Settings() {
  const { account, balance } = React.useContext(AccountContext);
  const [settings, setSettings] = React.useContext(SettingsContext);
  return (
    <SettingsPanel
      account={account}
      balance={balance}
      settings={settings}
      setSettings={setSettings}
    />
  );
}

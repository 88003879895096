import React from "react";
import { Redirect } from "@reach/router";
import { AccountContext, Login as LoginComponent } from "@had/shop";
import {
  PRODUCT_NAME,
  PRODUCT_SHORT_NAME,
  PRODUCT_VERSION
} from "../../constants/config";

import logo from "../../assets/img/logo.png";

export default function Login() {
  const { account } = React.useContext(AccountContext);
  return account === null ? (
    <LoginComponent
      logo={logo}
      name={PRODUCT_NAME}
      shortName={PRODUCT_SHORT_NAME}
      version={PRODUCT_VERSION}
    />
  ) : (
    <Redirect to="/" noThrow />
  );
}

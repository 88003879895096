import _objectWithoutPropertiesLoose from '@babel/runtime/helpers/esm/objectWithoutPropertiesLoose';
import React from 'react';
import axios from 'axios';
import { useTranslation, Trans } from 'react-i18next';
import _extends from '@babel/runtime/helpers/esm/extends';
import { Router, Redirect, Link } from '@reach/router';
import classNames from 'classnames';
import difference from 'lodash.difference';
import { object, string } from 'yup';
import { Field, ErrorMessage, Formik, Form } from 'formik';
import { SettingConstants } from '@had/common';
import BetPanelComponent from '@had/bet-panel';
var RELOAD_BALANCE_INTERVAL = 1000 * 30;
var AccountContext = React.createContext(null);

function AccountProvider(_ref) {
  var children = _ref.children,
      authUrl = _ref.authUrl,
      version = _ref.version;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var _React$useState = React.useState(null),
      account = _React$useState[0],
      setAccount = _React$useState[1];

  var _React$useState2 = React.useState(0),
      balance = _React$useState2[0],
      setBalance = _React$useState2[1];

  var cancelRef = React.useRef(null);
  React.useEffect(function () {
    return function () {
      return cancelRef.current && cancelRef.current();
    };
  }, []);
  var login = React.useCallback(function (_ref2, onError, onSuccess) {
    var username = _ref2.username,
        password = _ref2.password;
    if (cancelRef.current) cancelRef.current();
    var params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    params.append("version", version);
    axios.post(authUrl, params, {
      cancelToken: new axios.CancelToken(function (c) {
        return cancelRef.current = c;
      })
    }).then(function (_ref3) {
      var _ref3$data = _ref3.data,
          balance = _ref3$data.balance,
          rest = _objectWithoutPropertiesLoose(_ref3$data, ["balance"]);

      setAccount(rest);
      setBalance(balance);
      onSuccess(rest);
    }).catch(function (error) {
      onError(error.response ? error.response.data.error || t("app.server_error") : t("app.server_no_response"));
    });
  }, [t, cancelRef]);
  var refreshBalance = React.useCallback(function () {
    if (account === null) return;
    var shopUrl = account.shopUrl,
        token = account.token;
    if (cancelRef.current) cancelRef.current();
    axios.get(shopUrl + "/player", {
      headers: {
        Authorization: "Bearer " + token
      },
      cancelToken: new axios.CancelToken(function (c) {
        return cancelRef.current = c;
      })
    }).then(function (_ref4) {
      var balance = _ref4.data.balance;
      return setBalance(balance);
    }).catch(function () {});
  }, [account]);
  var debitBalance = React.useCallback(function (amount) {
    if (account === null || !amount) return false;
    var shopUrl = account.shopUrl,
        token = account.token;
    return axios.put(shopUrl + "/player/" + amount, null, {
      headers: {
        Authorization: "Bearer " + token
      }
    }).then(function (_ref5) {
      var balance = _ref5.data.balance;
      setBalance(balance);
      return true;
    }).catch(function () {
      return false;
    });
  }, [account]);
  var logout = React.useCallback(function () {
    setBalance(0);
    return setAccount(null);
  }, []);
  var context = React.useMemo(function () {
    return {
      account: account,
      balance: balance,
      login: login,
      logout: logout,
      refreshBalance: refreshBalance,
      debitBalance: debitBalance
    };
  }, [account, balance, login, logout, refreshBalance, debitBalance]);
  React.useEffect(function () {
    var interval = window.setInterval(refreshBalance, RELOAD_BALANCE_INTERVAL);
    return function () {
      return window.clearInterval(interval);
    };
  }, [refreshBalance]);
  return React.createElement(AccountContext.Provider, {
    value: context
  }, children);
}

var SettingsContext = React.createContext([{}, function () {}]);

function SettingsProvider(_ref) {
  var children = _ref.children,
      get = _ref.get,
      set = _ref.set;

  var _React$useState = React.useState(function () {
    return get();
  }),
      settings = _React$useState[0],
      setSettingsInternal = _React$useState[1];

  var setSettings = React.useCallback(function (newSettings) {
    return setSettingsInternal(function (prevSettings) {
      var changes = Object.entries(newSettings).reduce(function (curr, _ref2) {
        var key = _ref2[0],
            value = _ref2[1];
        if (prevSettings.hasOwnProperty(key) && value !== prevSettings[key]) curr[key] = value;
        return curr;
      }, {});
      if (Object.keys(changes).length === 0) return prevSettings;
      return _extends({}, prevSettings, changes);
    });
  }, [setSettingsInternal]);
  var context = React.useMemo(function () {
    return [settings, setSettings];
  }, [setSettings, settings]);
  var mounted = React.useRef(false);
  React.useEffect(function () {
    if (!mounted.current) mounted.current = true;else set(settings);
  }, [settings]);
  return React.createElement(SettingsContext.Provider, {
    value: context
  }, children);
}

var logoutIcon = "data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20version%3D%221.1%22%20id%3D%22Capa_1%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%20512%20512%22%20style%3D%22enable-background%3Anew%200%200%20512%20512%3B%22%20xml%3Aspace%3D%22preserve%22%20width%3D%22512px%22%20height%3D%22512px%22%3E%3Cg%3E%3Cg%3E%20%3Cg%3E%20%20%3Cpath%20d%3D%22M255.15%2C468.625H63.787c-11.737%2C0-21.262-9.526-21.262-21.262V64.638c0-11.737%2C9.526-21.262%2C21.262-21.262H255.15%20%20%20%20c11.758%2C0%2C21.262-9.504%2C21.262-21.262S266.908%2C0.85%2C255.15%2C0.85H63.787C28.619%2C0.85%2C0%2C29.47%2C0%2C64.638v382.724%20%20%20%20c0%2C35.168%2C28.619%2C63.787%2C63.787%2C63.787H255.15c11.758%2C0%2C21.262-9.504%2C21.262-21.262%20%20%20%20C276.412%2C478.129%2C266.908%2C468.625%2C255.15%2C468.625z%22%20data-original%3D%22%23000000%22%20class%3D%22active-path%22%20data-old_color%3D%22%23ffffff%22%20fill%3D%22%23ffffff%22%2F%3E%20%3C%2Fg%3E%3C%2Fg%3E%3Cg%3E%20%3Cg%3E%20%20%3Cpath%20d%3D%22M505.664%2C240.861L376.388%2C113.286c-8.335-8.25-21.815-8.143-30.065%2C0.213s-8.165%2C21.815%2C0.213%2C30.065l92.385%2C91.173%20%20%20%20H191.362c-11.758%2C0-21.262%2C9.504-21.262%2C21.262c0%2C11.758%2C9.504%2C21.263%2C21.262%2C21.263h247.559l-92.385%2C91.173%20%20%20%20c-8.377%2C8.25-8.441%2C21.709-0.213%2C30.065c4.167%2C4.21%2C9.653%2C6.336%2C15.139%2C6.336c5.401%2C0%2C10.801-2.041%2C14.926-6.124l129.276-127.575%20%20%20%20c4.04-3.997%2C6.336-9.441%2C6.336-15.139C512%2C250.302%2C509.725%2C244.88%2C505.664%2C240.861z%22%20data-original%3D%22%23000000%22%20class%3D%22active-path%22%20data-old_color%3D%22%23ffffff%22%20fill%3D%22%23ffffff%22%2F%3E%20%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%20%3C%2Fsvg%3E";

var isActive = function isActive(_ref) {
  var isPartiallyCurrent = _ref.isPartiallyCurrent;
  var className = classNames("btn", "btn-primary", "mr-2", "nav-link", {
    active: isPartiallyCurrent
  });
  return {
    className: className
  };
};

var PartialNavLink = function PartialNavLink(props) {
  return React.createElement(Link, _extends({
    getProps: isActive
  }, props));
};

var Header = React.memo(function (_ref2) {
  var routes = _ref2.routes;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var _React$useContext = React.useContext(AccountContext),
      profilePermissions = _React$useContext.account.permissions,
      logout = _React$useContext.logout;

  function onLogout() {
    if (window.confirm(t("app.confirm_logout"))) logout();
  }

  var filteredRoutes = routes.filter(function (_ref3) {
    var _ref3$permissions = _ref3.permissions,
        routePermissions = _ref3$permissions === void 0 ? [] : _ref3$permissions;
    return routePermissions.length === 0 || difference(routePermissions, profilePermissions).length === 0;
  }); //NOTE:Handle ctrl+l for logout

  function keyPressButton(evt) {
    if (evt.ctrlKey && evt.keyCode === 76) onLogout();
  }

  React.useEffect(function () {
    document.addEventListener("keydown", keyPressButton, false);
    return function () {
      document.removeEventListener("keydown", keyPressButton, false);
    };
  });
  if (filteredRoutes.length <= 1) return null;
  return React.createElement("div", null, React.createElement("nav", {
    className: "navbar navbar-expand-lg bg-primary p-1"
  }, React.createElement("div", {
    className: "collapse navbar-collapse"
  }, React.createElement("ul", {
    className: "nav navbar-nav"
  }, filteredRoutes.map(function (_ref4) {
    var img = _ref4.img,
        path = _ref4.path,
        content = _ref4.content;
    return React.createElement("li", {
      className: "nav-item",
      key: path
    }, React.createElement(PartialNavLink, {
      to: path,
      className: "nav-link"
    }, React.createElement("img", {
      src: img,
      style: {
        width: "1.5rem"
      },
      className: "mr-2",
      alt: content
    }), React.createElement("strong", null, content)));
  })), React.createElement("ul", {
    className: "nav navbar-nav ml-auto"
  }, React.createElement("li", {
    className: "nav-item"
  }, React.createElement("button", {
    className: "btn btn-primary nav-link",
    onClick: onLogout
  }, React.createElement("img", {
    src: logoutIcon,
    style: {
      width: "1.5rem"
    },
    className: "mr-2",
    alt: t("app.logout")
  }), React.createElement("strong", null, t("app.logout"))))))));
});

function Authenticated(_ref5) {
  var children = _ref5.children,
      routes = _ref5.routes;
  return React.createElement(React.Fragment, null, React.createElement(Header, {
    routes: routes
  }), React.createElement(Router, {
    className: "h-100 flex-grow-1 d-flex flex-column overflow-hidden"
  }, routes.map(function (_ref6) {
    var path = _ref6.path,
        match = _ref6.match,
        Component = _ref6.component;
    return React.createElement(Component, {
      path: match || path,
      key: path
    });
  }), React.createElement(Redirect, {
    from: "/",
    to: routes[0].path,
    noThrow: true
  })), children);
}

var instanceCount = 0;
var VALIDATION_SCHEMA = object().shape({
  username: string().trim().required(),
  password: string().trim().required()
});
var FieldSet = React.forwardRef(function (_ref, ref) {
  var _ref$component = _ref.component,
      component = _ref$component === void 0 ? "input" : _ref$component,
      _render = _ref.render,
      name = _ref.name,
      _ref$showError = _ref.showError,
      showError = _ref$showError === void 0 ? true : _ref$showError,
      _ref$label = _ref.label,
      label = _ref$label === void 0 ? name : _ref$label,
      validate = _ref.validate,
      children = _ref.children,
      disabled = _ref.disabled,
      rest = _objectWithoutPropertiesLoose(_ref, ["component", "render", "name", "showError", "label", "validate", "children", "disabled"]);

  return React.createElement(Field, {
    name: name,
    validate: validate,
    render: function render(_ref2) {
      var field = _ref2.field,
          form = _ref2.form;
      var errors = form.touched[name] && form.errors[name];
      var fieldDisabled = disabled ? disabled : form.isSubmitting;
      return _render ? _render(_extends({
        field: field,
        form: form,
        errors: errors
      }, rest)) // render prop inception
      : component && React.createElement("div", {
        className: "form-group"
      }, Boolean(label) && React.createElement("label", {
        htmlFor: name,
        className: "form-label"
      }, label), React.createElement(component, _extends({}, field, rest, {
        disabled: fieldDisabled,
        className: classNames("form-control", {
          "is-invalid": errors
        }),
        invalid: (!!errors).toString(),
        ref: ref
      })), children, showError && React.createElement(ErrorMessage, {
        name: name
      }, function (errors) {
        return [].concat(errors || []).map(function (error, index) {
          return React.createElement("div", {
            className: "invalid-feedback d-block",
            key: index
          }, error);
        });
      }));
    }
  });
});

function FormElement(_ref3) {
  var isSubmitting = _ref3.isSubmitting,
      globalError = _ref3.errors.global,
      isValid = _ref3.isValid,
      submitForm = _ref3.submitForm,
      onExit = _ref3.onExit,
      autoLogin = _ref3.autoLogin;
  instanceCount += 1;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var ref = React.useRef(null);
  var autoLoginRef = React.useRef(isValid && autoLogin && instanceCount === 1);
  React.useEffect(function () {
    ref.current.focus();
  }, []);
  React.useEffect(function () {
    if (autoLoginRef.current) submitForm();
    autoLoginRef.current = false;
  }, [autoLoginRef, submitForm]);
  return React.createElement(Form, null, globalError && React.createElement("div", {
    className: "alert alert-danger"
  }, globalError), React.createElement(FieldSet, {
    name: "username",
    label: false,
    placeholder: t("app.username"),
    ref: ref
  }), React.createElement(FieldSet, {
    name: "password",
    type: "password",
    label: false,
    placeholder: t("app.password")
  }), React.createElement("div", {
    className: "d-flex flex-row"
  }, React.createElement("div", {
    className: "flex-grow-1 flex-basis-0 "
  }, React.createElement("button", {
    className: "btn btn-block btn-primary",
    type: "submit",
    disabled: isSubmitting
  }, isSubmitting ? React.createElement("div", {
    className: "spinner-border spinner-border-sm text-light"
  }) : t("app.submit"))), onExit && React.createElement("div", {
    className: "flex-grow-1 flex-basis-0 ml-1"
  }, React.createElement("button", {
    className: "btn btn-block btn-danger",
    onClick: onExit
  }, t("app.exit")))));
}

function LoginForm(_ref4) {
  var _onSubmit = _ref4.onSubmit,
      rest = _objectWithoutPropertiesLoose(_ref4, ["onSubmit"]);

  var _React$useContext = React.useContext(SettingsContext),
      autoLogin = _React$useContext[0][SettingConstants.AUTO_LOGIN];

  return React.createElement(Formik, {
    initialValues: {
      username: autoLogin ? localStorage.getItem("username") || "" : "",
      password: autoLogin ? localStorage.getItem("password") || "" : ""
    },
    isInitialValid: function isInitialValid(_ref5) {
      var _ref5$initialValues = _ref5.initialValues,
          username = _ref5$initialValues.username,
          password = _ref5$initialValues.password;
      return username !== "" && password !== "";
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: function onSubmit(_ref6, _ref7) {
      var username = _ref6.username,
          password = _ref6.password;
      var setSubmitting = _ref7.setSubmitting,
          setErrors = _ref7.setErrors;
      return _onSubmit({
        username: username,
        password: password
      }, function (error) {
        setErrors({
          global: [error]
        });
        setSubmitting(false);
      }, function () {
        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
      });
    },
    render: function render(props) {
      return React.createElement(FormElement, _extends({}, props, rest, {
        autoLogin: autoLogin
      }));
    }
  });
}

function Login(_ref8) {
  var logo = _ref8.logo,
      name = _ref8.name,
      shortName = _ref8.shortName,
      version = _ref8.version,
      onExit = _ref8.onExit;

  var _useTranslation2 = useTranslation(),
      t = _useTranslation2.t;

  var _React$useContext2 = React.useContext(AccountContext),
      login = _React$useContext2.login;

  return React.createElement("div", {
    className: "flex-grow-1 d-flex align-items-center justify-content-center"
  }, React.createElement("div", {
    style: {
      width: "25rem"
    }
  }, React.createElement("div", {
    className: "card"
  }, React.createElement("div", {
    className: "card-body"
  }, React.createElement("div", {
    className: "d-flex justify-content-between align-items-center"
  }, React.createElement("div", null, React.createElement("h5", {
    className: "card-title"
  }, t("app.sign_in")), React.createElement("h6", {
    className: "card-subtitle text-muted"
  }, React.createElement(Trans, {
    i18nKey: "app.continue_to_product"
  }, "Continue to ", {
    shortName: shortName
  }))), React.createElement("img", {
    src: logo,
    alt: "logo",
    style: {
      width: "5rem"
    }
  })), React.createElement("div", {
    className: "mt-4"
  }, React.createElement(LoginForm, {
    onSubmit: login,
    onExit: onExit
  })))), React.createElement("div", {
    className: "d-flex justify-content-between align-items-center mt-1"
  }, React.createElement("small", null, name), React.createElement("small", null, "v " + version))));
}

function BetPanel(_ref) {
  var onBet = _ref.onBet;

  var _React$useContext = React.useContext(AccountContext),
      account = _React$useContext.account,
      balance = _React$useContext.balance,
      refreshBalance = _React$useContext.refreshBalance;

  var onBetWrapper = React.useCallback(function (props) {
    onBet(props);
    refreshBalance();
  }, [onBet, refreshBalance]);

  var _React$useContext2 = React.useContext(SettingsContext),
      settings = _React$useContext2[0];

  return React.createElement(BetPanelComponent, {
    settings: settings,
    account: account,
    balance: balance,
    onBet: onBetWrapper
  });
}

export { AccountContext, AccountProvider, Authenticated, BetPanel, Login, SettingsContext, SettingsProvider };
import bwipjs from "bwip-js";
import { formatDate, formatNumber, formatPrice, formatTime } from "@had/i18n";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import printJS from "print-js";
import image2base64 from "image-to-base64";

import logo from "../assets/img/logo.png";

const PRINT_LOCALE = "en";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
let logo64 = null;

/*
  The formula for calculating width and height values for a page is:
  value in inch * 72
  or
  value in mm / 0.35277
   */
const docDefinition = {
  pageSize: {
    width: 80 / 0.35277,
    height: "auto"
  },
  pageMargins: [23, 0, 23, 0],
  styles: {
    header: {
      fontSize: 18,
      bold: true,
      margin: [0, 0, 0, 0]
    },
    tableExample: {
      margin: [0, 0, 0, 3]
    },
    tableExampleBold: {
      margin: [0, 0, 0, 3],
      bold: true
    },
    tableLineExample: {
      margin: [0, 0, 0, 3]
    },
    tableHeader: {
      bold: true
    }
  },
  defaultStyle: {
    fontSize: 8
  }
};

const alignRight = text => ({
  text,
  alignment: "right"
});

const createBarcodeImage = (text, opt) => {
  opt = opt || {};
  const format = opt.format || "code39"; //default barcode type
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    return bwipjs(
      canvas,
      {
        bcid: format,
        text: text,
        width: 40,
        height: 10,
        paddingwidth: 20
      },
      function(err) {
        if (err) {
          return reject(err);
        }
        return resolve(canvas.toDataURL("image/png"));
      }
    );
  });
};

async function makeBet(bet, t, lng, timeZone, shortPrint, players, shop) {
  const barCode = await createBarcodeImage(bet.couponId);
  if (logo64 === null) logo64 = await image2base64(logo);
  const short = shortPrint && bet.paidDate !== undefined;
  const selections = short
    ? []
    : bet.selections.map(
        ({ meeting: { name }, event: { time }, selections: betSelections }) => {
          const selectionsArray = betSelections.map(
            ({ name: betSelectionName, num, price }) => [
              `${num}.${betSelectionName}`,
              price
                ? formatNumber(lng, formatPrice(price), {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                : t("app.price_SP")
            ]
          );

          return {
            style: "tableExample",
            table: {
              widths: ["*", "auto"],
              headerRows: 1,
              body: [
                [
                  {
                    text: `${name} ${formatTime(lng, time, { timeZone })}`,
                    style: "tableHeader",
                    colSpan: 2
                  },
                  {}
                ],
                ...selectionsArray
              ]
            },
            layout: "headerLineOnly"
          };
        }
      );

  const betData = [];

  if (!short)
    betData.push(
      [t("settings.account.profile.shop"), alignRight(shop)],
      [t("app.createdBy"), alignRight(players[bet.createdBy])],
      [
        t("app.createdDate"),
        alignRight(
          `${formatDate(lng, bet.createdDate, { timeZone })} ${formatTime(
            lng,
            bet.createdDate,
            { timeZone, second: "numeric" }
          )}`
        )
      ],
      [
        t("app.betType"),
        alignRight(
          `${t(`betType.${bet.type}`)}${bet.eachWay ? ` ${t("app.EW")}` : ""}`
        )
      ],
      [
        t("app.total_stake"),
        alignRight(
          formatNumber(lng, bet.total, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        )
      ],
      [
        t("app.potential_returns"),
        alignRight(
          bet.possibleWin
            ? formatNumber(lng, bet.possibleWin, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            : t("app.not_available_short")
        )
      ]
    );

  if (bet.win !== undefined && bet.win > 0 && bet.paidDate !== undefined)
    betData.push(
      [
        t("app.actual_returns"),
        alignRight(
          formatNumber(lng, bet.win, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        )
      ],
      [t("app.paidBy"), alignRight(players[bet.paidBy])],
      [
        t("app.paidDate"),
        alignRight(
          `${formatDate(lng, bet.paidDate, { timeZone })} ${formatTime(
            lng,
            bet.paidDate,
            { timeZone, second: "numeric" }
          )}`
        )
      ]
    );

  const now = new Date();
  return [
    {
      image: `data:image/png;base64,${logo64}`,
      width: 100,
      alignment: "center"
    },
    {
      text: `${formatDate(lng, now, { timeZone })} ${formatTime(lng, now, {
        timeZone,
        second: "numeric"
      })}`,
      alignment: "center",
      margin: [0, 5, 0, 10]
    },
    ...selections,
    {
      style: "tableLineExample",
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [[""], [""]]
      },
      layout: "headerLineOnly"
    },
    {
      style: "tableExampleBold",
      table: {
        widths: ["*", "auto"],
        body: betData
      },
      layout: "headerLineOnly"
    },
    {
      style: "tableLineExample",
      table: {
        headerRows: 1,
        widths: ["*"],
        body: [[""], [""]]
      },
      layout: "headerLineOnly"
    },
    { image: barCode },
    { text: bet.couponId, alignment: "center" }
  ];
}

export default function print(
  i18n,
  bets = [],
  timezone,
  shortPrint,
  players,
  shop
) {
  const t = i18n.getFixedT(PRINT_LOCALE);
  const result = bets.map(bet =>
    makeBet(bet, t, i18n.language, timezone, shortPrint, players, shop)
  );

  Promise.all(result).then(result => {
    pdfMake
      .createPdf({
        ...docDefinition,
        content: result.reduce((prev, curr, index) => {
          if (index > 0) curr[0] = { ...curr[0], pageBreak: "before" };
          prev = [...prev, ...curr];
          return prev;
        }, [])
      })

      .getBase64(function(encodedString) {
        printJS({ printable: encodedString, type: "pdf", base64: true });
      });
  });
}

import React from "react";
import { Redirect } from "@reach/router";
import { AccountContext, Authenticated as ShopAuthenticated } from "@had/shop";
import { Trans } from "react-i18next";
import Settings from "../pages/settings";
import Reports from "../pages/reports";
import BetPanel from "../pages/betpanel";

import homeIcon from "../../assets/img/home.svg";
import settingsIcon from "../../assets/img/settings.svg";
import reportsIcon from "../../assets/img/reports.svg";

const SETTINGS = "SETTINGS";
const REPORTS = "REPORTS";

const routes = [
  {
    img: homeIcon,
    content: <Trans i18nKey="menu.home" />,
    path: "/home",
    component: BetPanel
  },
  {
    img: reportsIcon,
    content: <Trans i18nKey="menu.reports" />,
    path: "/reports",
    match: "/reports/*",
    component: Reports,
    permissions: [REPORTS]
  },
  {
    img: settingsIcon,
    content: <Trans i18nKey="menu.settings" />,
    path: "/settings",
    match: "/settings/*",
    component: Settings,
    permissions: [SETTINGS]
  }
];

export default function AuthenticatedLayout() {
  const { account } = React.useContext(AccountContext);
  return account !== null ? (
    <ShopAuthenticated routes={routes} />
  ) : (
    <Redirect to="/login" replace={true} noThrow />
  );
}

import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { LocationProvider } from "@reach/router";
import { SettingsProvider } from "@had/shop";
import { DefaultSettings } from "@had/common";
const DEFAULT_SETTINGS = Object.entries(DefaultSettings).reduce(
  (curr, [key, value]) => {
    curr[key] =
      typeof value !== "object" || value === null ? value : value.default;
    return curr;
  },
  {}
);

const get = function() {
  const settings = localStorage.getItem("SETTINGS");
  if (settings === null) return {};

  return JSON.parse(settings);
};
const set = settings => {
  localStorage.setItem("SETTINGS", JSON.stringify(settings));
};

set(
  Object.entries({ ...get() }).reduce(
    (curr, [key, value]) => {
      if (curr.hasOwnProperty(key)) curr[key] = value;
      return curr;
    },
    { ...DEFAULT_SETTINGS }
  )
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <LocationProvider>
      <SettingsProvider get={get} set={set}>
        <App />
      </SettingsProvider>
    </LocationProvider>
  </I18nextProvider>,
  document.getElementById("root")
);

import React from "react";
import ReportsPanel from "@had/reports-panel";
import { useTranslation } from "react-i18next";
import { AccountContext, SettingsContext } from "@had/shop";
import { SettingConstants } from "@had/common";
import print from "../../utils/print";

export default function Reports() {
  const { i18n } = useTranslation();
  const { account, refreshBalance } = React.useContext(AccountContext);
  const [settings] = React.useContext(SettingsContext);
  const printBet = React.useCallback(
    bets => {
      const {
        players,
        profile: { shop }
      } = account;
      const {
        [SettingConstants.TIMEZONE]: timezone,
        [SettingConstants.SHORT_PRINTING]: shortPrint
      } = settings;
      print(i18n, bets, timezone, shortPrint, players, shop);
    },
    [account, i18n, settings]
  );

  return (
    <ReportsPanel
      account={account}
      settings={settings}
      refreshBalance={refreshBalance}
      printBet={printBet}
    />
  );
}

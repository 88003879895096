import React from "react";
import { useTranslation } from "react-i18next";
import { SettingsContext, BetPanel as BetPanelComponent } from "@had/shop";
import { SettingConstants } from "@had/common";
import print from "../../utils/print";

export default function BetPanel() {
  const { i18n } = useTranslation();
  const [settings] = React.useContext(SettingsContext);
  const onBet = React.useCallback(
    ({ state, action }) => {
      const {
        account: {
          players,
          profile: { shop }
        }
      } = state;
      const { bets } = action;
      const {
        [SettingConstants.TIMEZONE]: timezone,
        [SettingConstants.SHORT_PRINTING]: shortPrint
      } = settings;

      print(i18n, bets, timezone, shortPrint, players, shop);
    },
    [settings, i18n]
  );

  return <BetPanelComponent onBet={onBet} />;
}
